<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div>
            <p>{{ $t('deposit.default.channels.ideal') }}</p>
            <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
          </div>
          <div class="form_main">
            <p class="title">
              {{ $t('deposit.method.form.header', { method: $t('deposit.default.channels.ideal') }) }}
            </p>
            <el-form label-position="top" :model="iDealForm" ref="iDealForm" status-icon :rules="iDealRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="validCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, iDealForm.amount)" prop="amount">
                      <numeric-input
                        v-model="iDealForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="iDealForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div class="logo circle bank"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import { autoForm } from '@/util/getAutoForm';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import { apiIDeal_banxa_payment } from '@/resource';

export default {
  name: 'iDeal',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC })));
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      iDealForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      iDealRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      maxLimit: 30000,
      validCurrencies: ['EUR'],
      instData: [this.$t('deposit.reminder.inst1'), this.$t('deposit.reminder.inst13')],
      showNote: false
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.iDealForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiIDeal_banxa_payment(
        {
          mt4Account: this.iDealForm.accountNumber,
          operateAmount: this.iDealForm.amount,
          applicationNotes: this.iDealForm.notes
        },
        this.token
      );
    },
    submitForm() {
      this.$refs['iDealForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.msg && result.data.code == 0) {
                window.location = result.data.msg;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
